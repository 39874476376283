/* Animation */

.parallax > use {
	animation: move-forever 50s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
	animation-delay: -2s;
	animation-duration: 14s;
}
.parallax > use:nth-child(2) {
	animation-delay: -1s;
	animation-duration: 20s;
}
.parallax > use:nth-child(3) {
	animation-delay: -4s;
	animation-duration: 26s;
}
.parallax > use:nth-child(4) {
	animation-delay: -7s;
	animation-duration: 40s;
}
@keyframes move-forever {
	0% {
		transform: translate3d(-90px, 0, 0);
	}
	50% {
		transform: translate3d(15px, 0, 0);
	}
	100% {
		transform: translate3d(85px, 0, 0);
	}
}
